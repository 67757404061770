<template>
  <div class="dinput-select">
    <select class="sl-input" v-model="response.value">
      <option :value="null" disabled>Selecione</option>
      <option v-for="(v, key) in optionValues" :key="'val-' + key">{{v}}</option>
    </select>
  </div>
</template>

<script>
import Mixin from "@/components/forms/input/dynamic/inputs/Mixin"

export default {
  name: "DSelect",
  mixins: [Mixin]
}
</script>
