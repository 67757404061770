<template>
  <div class="dinput-date">
    <datetime-input :date-format="format" :date-type="this.type === 'datetime' ? 'datetime' : 'date'" v-model="response.value" />
  </div>
</template>

<script>
import Mixin from "@/components/forms/input/dynamic/inputs/Mixin"
import DatetimeInput from '@/reuse/input/Datetime'

export default {
  name: "DDate",
  components: {DatetimeInput},
  mixins: [Mixin],
  computed: {
    format () {
      return this.type === 'datetime' ? '##/##/#### ##:##' : '##/##/####'
    }
  }
}
</script>
