export default {
    props: {
        field: {required: true},
        response: {required: true},
        value: {required: true}
    },
    data () {
        return {
            model: this.value
        }
    },
    computed: {
        type() {
            return this.field.type.id || this.field.type
        },
        label () {
            return this.field && this.field.name
        },
        optionValues () {
            const v = this.field && this.field.config && this.field.config.optionValues && this.field.config.optionValues.split('\n')
            return v || [];
        },
        placeholder () {
            return this.field && this.field.config && this.field.config.placeholder
        },
        tip () {
            return this.field && this.field.config && this.field.config.tip
        },
        readonly () {
            return this.field && this.field.config && this.field.config.readonly
        },
        labelPosition () {
            return this.field && this.field.config && this.field.config.labelPosition
        },
        height () {
            return this.field && this.field.config && this.field.config.height
        },
        layout () {
            return this.field && this.field.config && this.field.config.layout
        },
        bindFieldClass () {
            const css = []
            if (this.field) {
                if (this.field.config.labelBold) {
                    css.push('font-bold')
                }
                if (this.field.config.inputBold) {
                    css.push('input-bold')
                }
            }
            return css
        }
    }
}
