<template>
  <div class="dinput-options">
    <div v-for="(v, key) in optionValues" :key="'val-' + key" class="option-item">
      <label v-if="v != '{informar}'" class="option-item-content">
        <div class="item-input"><input type="radio" v-model="response.value" :value="v" /></div> {{v}}
      </label>
      <label v-else class="option-item-content">
        <div class="item-input"><input type="radio" v-model="response.value" :value="v" /></div> <input :disabled="response.value !== v" v-model="response.valorExtra" type="text" :placeholder="placeholder" class="erp-input radio-extra" /></label>
    </div>
  </div>
</template>

<script>
import Mixin from "@/components/forms/input/dynamic/inputs/Mixin"

export default {
  name: "DRadio",
  mixins: [Mixin]
}
</script>
