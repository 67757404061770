<template>
  <div class="dinput-checkbox">
    <sl-checkbox :true-value="trueValue" :false-value="falseValue" v-model="response.value" />
  </div>
</template>

<script>
import Mixin from "@/components/forms/input/dynamic/inputs/Mixin"
import SlCheckbox from "@/reuse/input/Checkbox"

export default {
  name: "DCheckbox",
  props: {
    trueValue: {required: false},
    falseValue: {required: false},
  },
  components: {SlCheckbox},
  mixins: [Mixin]
}
</script>
