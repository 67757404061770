<template>
  <div class="dinput dinput-v2" v-if="!form || !form.mode || form.mode === 'edit'">
    <erp-s-field :label="label" :icon-help="tip" :class="bindFieldClass" :view="labelPosition">
      <textarea :readonly="readonly" v-if="type === 'largetext'" :placeholder="placeholder" v-on:input="$emit('input', $event.target.value)" class="form-textarea sl-textarea" v-model="model" :style="height && height < 500 ? 'height: ' + height + 'px' : null"/>
      <d-select v-else-if="type === 'select'" :field="field" :response="response" v-on:input="$emit('input', value)" v-model="model" class="form-select" />
      <d-radio v-else-if="type === 'option'" :field="field" :response="response" v-on:input="$emit('input', value)" v-model="model" class="form-radio" />
      <d-checkbox v-else-if="type === 'checkbox'" true-value="1" false-value="0" :field="field" :response="response" v-on:input="$emit('input', value)" v-model="model" class="form-checkbox" />
      <d-date v-else-if="type === 'date' || type === 'datetime'" :field="field" :response="response" v-on:input="$emit('input', value)" v-model="model" class="form-ddate" />
      <input v-else :readonly="readonly" :placeholder="placeholder" v-on:input="$emit('input', $event.target.value)" class="form-input sl-input" v-model="model"/>
    </erp-s-field>
  </div>
  <div class="dinput dinput-v2" v-else>
    <erp-s-field :label="label" :icon-help="tip" :class="bindFieldClass" :view="labelPosition">
      {{translateValue(response)}}
    </erp-s-field>
  </div>
</template>

<script>
// import {inputTypes} from "@/components/forms/input/dynamic/Helper"
import DRadio from "@/components/forms/input/dynamic/inputs/DRadio"
import DSelect from "@/components/forms/input/dynamic/inputs/DSelect"
import DCheckbox from "@/components/forms/input/dynamic/inputs/DCheckbox"
import DDate from "@/components/forms/input/dynamic/inputs/DDate"
import Mixin from "components/forms/input/dynamic/inputs/Mixin"
import {ErpSField} from "@/plugins/uloc-erp"

export default {
  name: "DetectInputV2",
  inject: ['form'],
  mixins: [Mixin],
  components: {
    ErpSField,
    DRadio,
    DSelect,
    DCheckbox,
    DDate
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    translateValue (response) {
      if (response.field.type === 'checkbox') {
        return response.value == '1' ? 'Sim' : 'Não'
      }
      return response.value
    }
  }
}
</script>
